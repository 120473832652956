// --- LAYOUT ---

html,
body, 
app-root {
  width: 100%;
  height: 100%;
  display: block;
}

app-root {
  .app-wrapper {
    overflow: hidden;
    width: 100%;
    height: calc(100% - 69px);
    
    // common behavior
    app-search,
    app-browse,
    app-periodical,
    app-music,
    app-home {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: row;
    }
    app-search-filters,
    app-browse-filters,    
    app-navigation,
    app-metadata,
    app-periodical-filters {
      width: $app-side-col-width;
      height: 100%;
      z-index: 10;
      background-color: $app-grey-l-5;
      border-right: 1px solid $app-grey-l-2;
    }
    
    // book
    app-book {
      display: block;
      height: 100%;
      width: 100%;
      position: relative;
    }

    app-metadata {
      margin-left: auto;
      transition: margin-right 0.3s;
      border-left: 1px solid $app-grey-l-2;
      &.app-panel-hidden {
        margin-right: -$app-side-col-width;
        overflow: hidden;
      }
      @media #{$medium-and-down} {
        &.app-book-metadata {
          &.app-panel-mobile-hidden {
            margin-right: -$app-side-col-width;
            transition: margin-right 0.0s;
            overflow: hidden;
          }
          &.app-panel-hidden {
            margin-right: 0;
            overflow: inherit;
          }
        }
      }
      @media #{$small-and-down} {
        &:not(.app-nested) {
          position: absolute;
          width: 100%;
          top: calc(#{$navbar-height} + #{$app-toolbar-height});
          left: 0;
          border: 0;
          height: calc(#{$app-mobile-panel-height} - #{$navbar-height} - #{$app-toolbar-height});
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
          display: block;
          z-index: 950;
        }
        &.app-panel-mobile-hidden {
          display: none;
        }
        &.app-book-metadata {
          top: $app-toolbar-height;
          height: calc(#{$app-mobile-panel-height} - #{$app-toolbar-height});
        }
      }
      &.app-nested {
        width: auto;
        margin: 5px 0 20px;
        border-left: 5px solid $app-grey-l-2;
        display: block;
        .app-metadata-wrapper {
          overflow: hidden;
          .app-metadata-content {
            padding: 8px 0 0 8px;
            // margin-bottom: -10px;
          }
        }
      }
    }

  }

}