// colors
$primary-color:             #0277bd !default; // 0277bd -> hlavni barva, logo, titulky, aktivni filtry, aktivni bg
$primary-color-light:       lighten($primary-color, 15%) !default;
$primary-color-dark:        darken($primary-color, 15%) !default;

// custom
$app-grey:                  #9e9e9e !default; // 9e9e9e -> arrow back next to logo
$app-grey-l-5:              #fafafa !default; // fafafa -> bg navbar, bg facets, bg metadata
$app-grey-l-4:              #f5f5f5 !default; // f5f5f5
$app-grey-l-3:              #eeeeee !default; // eeeeee
$app-grey-l-2:              #e0e0e0 !default; // e0e0e0 -> item border, search box border, facet separator line
$app-grey-l-1:              #bdbdbd !default; // bdbdbd -> light mdi color

$app-grey-d-1:              #757575 !default; // 757575 -> text color, dark mdi color
$app-grey-d-2:              #616161 !default; // 616161 -> text color darker
$app-grey-d-3:              #424242 !default; // 424242 -> metadada subtitle
$app-grey-d-4:              #212121 !default; // 212121 -> text color black - facet title, arrows etc.

$app-grey-c-1:              #050505 !default; // ebebeb -> tabs line, tabs of document type line

$app-grey-text:             #707070 !default;

$app-red:                   #d50000 !default; // d50000 -> remove color

$app-licence-color: #d27e0f;
// type of document
$app-type-monograph:        #c73565 !default; // ec407a -> knihy
// $app-type-monograph:        #ec407a !default; // ec407a -> knihy
$app-type-map:              #735F32 !default; // c0ca33 -> mapy
$app-type-periodical:       #167a1b !default; // 66bb6a -> noviny a casopisy
$app-type-graphic:          #dc2522 !default; // ef5350 -> grafiky
$app-type-soundrecording:   #af580b !default; // ffab40 -> audio
$app-type-archive:          #144272 !default; // 00bfa5 -> archivalie
$app-type-sheetmusic:       #7e4ad8 !default; // 9575cd -> hudebniny
$app-type-manuscript:       #3F4E4F !default; // 78909c -> rukopisy
$app-type-clippingsvolume: #7b4713 !default;
$app-type-convolute: #7b4713 !default;
$app-type-article: #2858be !default;
$app-type-internalpart: #2858be !default;
$app-type-page: #8B4513 !default;
$app-type-collection: #000000 !default;


// global
$app-border-radius:         2px !default;
$app-border-radius-sidebar: 4px !default;

// search bar
$app-search-bar-height:     44px !default;

// fulltext search bar
$app-fulltext-search-bar-height: 47px !default;

// layout
$app-side-col-width:        300px !default;

// logo
$app-mobile-logo-width:     115px !default;

// toolbar
$app-toolbar-height:        55px !default;

// mobile panel - facets, metadata
$app-mobile-panel-height:   100% !default;

// card
$app-card-title-font-size:  1.1rem !default;

$navbar-height:             68px !default;

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$small-800-screen: 800px !default;
$small-500-screen: 500px !default;
$small-400-screen: 400px !default;
$small-350-screen: 350px !default;
$small-300-screen: 300px !default;
$small-200-screen: 200px !default;


$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;
$large-only: "only screen and (max-width : #{$large-screen}) and (min-width : #{$medium-screen})" !default;

$small-800-and-down: "only screen and (max-width : #{$small-800-screen})" !default;
$small-500-and-down: "only screen and (max-width : #{$small-500-screen})" !default;
$small-400-and-down: "only screen and (max-width : #{$small-400-screen})" !default;
$small-350-and-down: "only screen and (max-width : #{$small-350-screen})" !default;
$small-300-and-down: "only screen and (max-width : #{$small-300-screen})" !default;
$small-200-and-down: "only screen and (max-width : #{$small-200-screen})" !default;