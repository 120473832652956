
ul {
  &:not(.browser-default) {
    padding-left: 0;
    list-style-type: none;
    margin: 0;
    & > li {
      list-style-type: none;
    }
  }
}

a {
	color: $primary-color;
	text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

/*********************
  Media Query Classes
**********************/
.hide-on-small-only, .hide-on-small-and-down {
  @media #{$small-and-down} {
    display: none !important;
  }
}
.hide-on-med-and-down {
  @media #{$medium-and-down} {
    display: none !important;
  }
}
.hide-on-med-and-up {
  @media #{$medium-and-up} {
    display: none !important;
  }
}
.hide-on-med-only {
  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    display: none !important;
  }
}
.hide-on-large-only {
  @media #{$large-and-up} {
    display: none !important;
  }
}
.show-on-large {
  @media #{$large-and-up} {
    display: block !important;
  }
}
.show-on-medium {
  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    display: block !important;
  }
}
.show-on-small {
  @media #{$small-and-down} {
    display: block !important;
  }
}
.show-on-medium-and-up {
  @media #{$medium-and-up} {
    display: block !important;
  }
}
.show-on-medium-and-down {
  @media #{$medium-and-down} {
    display: block !important;
  }
}


// Center text on mobile
.center-on-small-only {
  @media #{$small-and-down} {
    text-align: center;
  }
}



.hide {
  display: none !important;
}

// Text Align
.left-align {
  text-align: left;
}
.right-align {
  text-align: right
}
.center, .center-align {
  text-align: center;
}

.left {
  float: left !important;
}
.right {
  float: right !important;
}

// No Text Select
.no-select {
  user-select: none;
}

.circle {
  border-radius: 50%;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-padding {
  padding: 0 !important;
}

.app-lock-licence-open {
    color: #1e6b22 !important;
}

.app-lock-licence-locked {
    color: #b0441c !important;
}
