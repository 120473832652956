.app-dialog {
  .modal {
    max-width: 700px;
    .modal-content {
      padding-bottom: 0;
    }
  }


  .app-tabs {
    overflow: hidden;
    .app-dialog-title {
      float: left;
      padding-right: 8px;
      color: $primary-color;
      .app-citation-spec {
        font-size: 12px;
        color: $app-grey-d-1;
        margin-left: 2px;
      }
    }
    .app-tab {
      float: left;
      padding: 0 8px;
      color: $app-grey-d-1;
      cursor: pointer;
      border-left: 1px solid #ccc;
      &:hover {
        color: #000;
      }
      &.active {
        color: $primary-color;
      }
    }
    .dropdown-content {
      width: auto !important;
    }
  }


}