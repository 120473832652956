// --- NG DATEPICKER ---

.ngx-datepicker-container {
  position: relative;
  margin: 0 auto;

  .ngx-datepicker-input {
    padding: 5px 10px;
    font-size: 14px;
    width: 200px;
    outline: none;
    border: 1px solid $app-grey-c-1;
  }

  .ngx-datepicker-calendar-container {
    background-color: #fff;
    border-radius: $app-border-radius;
    box-shadow: 0 2px 2px 0 transparent, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 3px 1px -20px rgba(0, 0, 0, 0.1);
    // header
    .topbar-container {
      width: 100%;
      height: 50px;
      padding: 15px;
      border-bottom: 1px solid $app-grey-l-2;
      background-color: $app-grey-l-5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;

      .topbar-title {
        font-size: $app-card-title-font-size;
        margin: 0 auto;
      }
    }

    // content
    .main-calendar-container {
      width: 100%;
      height: 100%;
      padding: 15px 10px 0;
      font-size: 12px;
      font-weight: 500;

      .main-calendar-day-names {
        color: $app-grey-d-4;
        width: 100%;
        display: flex;
        align-items: center;

        .day-name-unit {
          width: calc(100% / 7);
          text-transform: uppercase;
          text-align: center;
        }
      }

      .main-calendar-days {
        padding: 15px 0;
        width: 100%;
        display: inline-block;
        height: 270px;
        overflow: hidden;

        .day-unit {
          width: calc(100% / 7);
          height: 40px;
          display: inline-flex;
          float: left;
          align-items: center;
          justify-content: center;
          user-select: none;

          .day-unit-inner {
            width: 36px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 50%;
            color: $app-grey;
            cursor: default;
            &.previous-month {
              color: $app-grey-l-2;
            }
          }
          a.day-unit-inner {
            font-weight: 500;
            color: #036cab;
            background-color: $app-grey-l-3;
            position: relative;;
            &:hover {
              background: $primary-color;
              color: #fff;
            }  
            .day-count {
              position: absolute;
              top: 5px;
              font-size: 18px;
              display: inline-block;
              left: 0;
              width: 36px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
