html, body { 
  height: 100%; 
}
body { 
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif; 
  font-size: 15px;
}

html{
  line-height: 1.5;
  @media only screen and (min-width: 0) {
    font-size: 14px;
  }
  @media only screen and (min-width: $medium-screen) {
    font-size: 14.5px;
  }
  @media only screen and (min-width: $large-screen) {
    font-size: 14px;
  }
}

* {
  box-sizing: border-box;    
}

:-ms-fullscreen {
  width: auto;
  height: auto;
  margin: auto;
}

a {
  cursor: pointer !important;
  text-decoration: none;
}

*:focus-visible {
  // background-color: yellow !important;
  // outline-style: solid !important;
  // outline-color: #0276bd !important;
  // background-color: yellow !important;
  // border: 3px solid #0276bd !important;
  outline: 3px solid $primary-color !important;
  // outline: 3px solid #ffa800 !important;
  // box-shadow: 0 0 0 3px  #ffa800 !important;
  outline-offset: -1px;


}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  &:focus-visible {
    outline: 3px solid #ffa800 !important;
  }
}


.app-paginator ul li.app-active a:focus-visible {
  outline: 3px solid #ffa800 !important;
}




.cdk-overlay-pane {
  max-width: 100vw !important;
  max-height: 100vh !important;

}

.mat-bottom-sheet-container-medium, .mat-bottom-sheet-container-large {
  max-width: 100vw !important; 
}

.mat-dialog-container {
  padding: 0 !important;
  // height: 100vh !important;
}

.ol-dragbox {
  background: #0277bd44;
  border: solid 1px #0277bd;
}

.app-dropdown-menu {
  .app-dropdown-item {
    display: block;
    overflow: hidden;
    padding: 10px 10px;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    height: 38px;
    color: $app-grey-d-1;
    &:hover {
      background: #e5e5e5;
    }
  }
}

mat-spinner.medium-centered-spinner {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.float-right {
  float: right;
}

input {
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: 0;
  height: 3rem;
  width: 100%;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: all .3s;
  border-bottom: 1px solid #9e9e9e;
}

// label {
//   font-size: .8rem;
//   color: $app-grey-text;
// }

::placeholder {
  color: $app-grey-l-1;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
-webkit-appearance: none;
}

.mat-expansion-panel-header {
  padding: 0 16px !important;
}

.mat-expansion-panel-body {
  padding: 0 16px 16px !important;
}

.mat-menu-panel {
  min-height: 50px !important;
  max-width: 400px !important;
}

label.app-hidden-label {
  display: none;
}

.mat-dialog-container:focus-visible {
  outline: none !important;
}

.app-visualy-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}


.app-snackbar-custom {
  background-color: #000; 
  color: white; 
  font-size: 14px; 
}

.material-icons {
  overflow: unset !important;
}